/* eslint-disable jsx-a11y/iframe-has-title */
import Banner from "../../sections/common/banner";
import { data } from "../../../globals/data/contact-us";
import { publicUrlFor } from "../../../globals/constants";

export default function ContactPage() {
    return (
        <>
            <Banner _data={data.banner} />
            
            <div className="section-full  p-t120 p-b120">
                <div className="section-content">
                    <div className="container">
                        <div className="gmap-outline mb-5">
                            <div className="google-map">
                                <div style={{ width: '100%' }}>
                                    <iframe height={460} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3112.5673155822897!2d-86.83655921091585!3d21.161157766171453!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c2d59a3fb34e1%3A0xf0dd80ca8096f2e0!2sVincomex!5e0!3m2!1sen!2smx!4v1688272808909!5m2!1sen!2smx" />
                                   
                                </div>
                            </div>
                        </div>
                        <div className="contact-one">
                            {/* TITLE START*/}
                            <div className="section-head left wt-small-separator-outer">
                                <h2 className="wt-title m-b30">Escribenos</h2>
                            </div>
                            {/* TITLE END*/}
                            {/* CONTACT FORM*/}
                            <div className="contact-one-inner  site-bg-sky-blue-light">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                        <div className="contact-form-outer">
                                            <form method="post" action="contacto.php">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group mb-3">
                                                            <input name="username" type="text" required className="form-control" placeholder="Nombre" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="form-group mb-3">
                                                            <input name="email" type="text" className="form-control" required placeholder="Email" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="form-group mb-3">
                                                            <input name="phone" type="text" className="form-control" required placeholder="Telefono" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-12">
                                                        <div className="form-group mb-3">
                                                            <input name="subject" type="text" className="form-control" required placeholder="Asunto" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-3">
                                                            <textarea name="message" className="form-control" rows={3} placeholder="Mensaje" defaultValue={""} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button type="submit" className="btn-half site-button"><span>Enviar</span><em /></button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="contact-info  site-bg-dark bg-no-repeat bg-bottom-left" style={{ backgroundImage: `url(${publicUrlFor('/assets/images/background/contact-bg.png')})` }}>
                                            {/* TITLE START*/}
                                            <div className="section-head-small white mb-4">
                                                <h3 className="wt-title">Toda nuestra información</h3>
                                            </div>
                                            {/* TITLE END*/}
                                            <div className="contact-info-section">
                                                <div className="c-info-column">
                                                    <div className="c-info-icon fa fa-map-marker"><span /></div>
                                                    <p><a href="https://goo.gl/maps/e3decsiorx63UZLi8">Av. Xel-Ha SM 28 Mza 10 lote 8 Local 11, Plaza Portales. Cancún Q.Roo</a></p>
                                                </div>
                                                <div className="c-info-column">
                                                    <div className="c-info-icon fa fa-mobile-phone custome-size"><span /></div>
                                                    <p>WhatsApp</p>
                                                    <p><a href="https://wa.me/5219987593468?text=Hola!%20me%20gustar%C3%ADa%20mas%20información%20de%20sus%20servicios.">+52 9987593468</a></p>
                                                    
                                                   
                                                </div>
                                                <div className="c-info-column">
                                                    <div className="c-info-icon fa fa-envelope-o"><span /></div>
                                                    <p><a href="mailto:contacto@cscargo.mx">contacto@cscargo.mx</a></p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}