import { NavLink } from "react-router-dom"
import { publicUrlFor } from "../../../globals/constants"

export default function Footer1() {
    return (
        <>
            <footer className="footer-dark">

            {/* NEWS LETTER SECTION START */}
            <div className="ftr-nw-ltr site-bg-white">
                
                    <div className="ftr-nw-ltr-inner site-bg-primary">
                        <div className="container">
                            <div className="ftr-nw-img">
                                <img src={publicUrlFor("/assets/images/news-l-bg.png")} alt="#" />
                            </div>
                            <div className="ftr-nw-content">
                            <a href="https://wa.me/5219987593468?text=Hola!%20me%20gustar%C3%ADa%20mas%20información%20de%20sus%20servicios." target="_blank">
                                <div className="ftr-nw-title">Contáctanos                                    
                                    <img src={publicUrlFor("/assets/images/WhatsAppButtonWhiteSmall.png")} alt="#" />
                                </div>
                            </a>
                                <div className="ftr-nw-form">
                                    <form action="enviarmail.php" method="post">
                                        <input name="news-letter" className="form-control" placeholder="Escribe tu mail" type="text" />
                                        <button className="ftr-nw-subcribe-btn">Enviar</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            {/* NEWS LETTER SECTION END */}

            <div className="ftr-bg"  style={{backgroundImage: `url(${publicUrlFor('/assets/images/f-bg.jpg')})`}}>
                {/* FOOTER BLOCKES START */}  
                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-6">
                                
                                <div className="widget widget_about">
                                    <div className="logo-footer clearfix">
                                        <NavLink to="/index"><img src={publicUrlFor("/assets/images/logo-dark.png")} alt="#" /></NavLink>
                                    </div>
                                    <p>Agentes navieros, expertos en transporte marítimo de carga.</p>
                                    
                                </div>                            
                                
                            </div>                        
                        
                            <div className="col-lg-4 col-md-6">
                                <div className="widget widget_services ftr-list-center">
                                    <h3 className="widget-title">Redes Sociales</h3>
                                    <ul className="social-icons">
                                        <li><a href="https://www.facebook.com/csccargo/" className="fa fa-facebook" target="_blank"></a></li>
                                        
                                        <li><a href="https://www.instagram.com/csccargo/" className="fa fa-instagram" target="_blank"> </a></li>
                                        
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="widget widget_services ftr-list-center">
                                    <h3 className="widget-title">Socios Comerciales</h3>
                                    <ul>
                                        <li><a href="https://www.vincomex.com.mx" target="_blank">Vincomex</a></li>
                                        <li><a href="#" target="_blank">Transporte de Carga Express</a></li>
                                       
                                       
                                    </ul>
                                </div>
                            </div>

                            

                        </div>

                    </div>
                </div>
                {/* FOOTER COPYRIGHT */}
                                    
                <div className="footer-bottom">
                    <div className="container">
                        <div className="footer-bottom-info">
                        
                            <div className="footer-copy-right">
                                <span className="copyrights-text">Pagina desarrollada por <a href="https://www.igeekcancun.com" target="_blank" style={{color: "orange"}}>iGeek Cancun</a></span>
                            </div>
                            
                        </div>
                    </div>   
                </div>
            </div>

            </footer>
        </>
    )
}