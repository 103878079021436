import { global } from "../constants";

export const data = {
  whatwedo: {
    title: "What we dooo!33",
    subtitle: "Specialist Logistics Services",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    image: global.icons + "img1.png",
    works: [
      {
        icon: global.icons + "pic1.png",
        serial: "01.",
        title: "Road Freight",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
      },
      {
        icon: global.icons + "pic2.png",
        serial: "02.",
        title: "Shipping Freight",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
      },
      {
        icon: global.icons + "pic3.png",
        serial: "03.",
        title: "Air Freight",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
      }
    ]
  },

  whychooseus: {
    title: "¿Por qué elegirnos?",
        subtitle: "Tenemos más de 25 años en el mundo de transporte de carga a nivel internacional",
        description: "Somos una agencia naviera con una trayectoria de más de 25 años en el mundo de transporte de carga a nivel internacional. Nos apasiona el transporte de carga de manera segura, eficaz y sostenible. Nuestro enfoque centrado en las personas, es simple, efectivo y está en el corazón de todo lo que hacemos.",
        branches: "1M+",
        since: "1998",
        specifications: [
            "Brindamos soluciones marítimas para el arribo y atención de los barcos a los puertos.",
            "Efectuamos trámites ante las autoridades, contratamos los servicios requeridos y controlamos que las operaciones se lleven a cabo de la manera más eficaz y económica posible.",
            "Mantenemos informados a los navieros y capitanes del desarrollo de las operaciones.",
            "Liberamos a los consignatarios las cargas que el barco ha descargado en cada puerto",
            "Llevamos a cabo los trámites necesarios para que se embarque la carga de exportación."
        ]
  },

  booking: {
    background: global.booking + "bg-map.png",
    image: global.booking + "pic1.png",
    imageBack: "",
    title: "Request A Quote",
    subtitle: "Booking For Product Transformation",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    form: {
      quote: global.booking + "icon1.png",
      track: global.booking + "icon2.png"
    }
  },

  estimation: {
    background: global.back + "bg-2.jpg",
    title: "Estimation",
    subtitle: "Has a wide range of solutions",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the  when an printer took.",
    solutions: [
      {
        serial: "01",
        title: "Solutions and specialized expertise",
        subtitle: "Our aim is to optimize and improve your supply chain so that we can give you the best service"
      },
      {
        serial: "02",
        title: "Multiple warehouses",
        subtitle: "We provide multiple drop off and pickup locations so you don't have to worry. And you should not face any kind..."
      },
      {
        serial: "03",
        title: "Tracking made easy",
        subtitle: "A tracking number for the entire process. so that you can find the exact position. it will help you"
      }
    ]
  },

  testimonials: {
    title: "Respected",
    subtitle: "Clients & partners",
    clients: [
      global.logo + "/w1.png",
      global.logo + "/w2.png",
      global.logo + "/w3.png",
      global.logo + "/w4.png",
      global.logo + "/w5.png",
      global.logo + "/w6.png",
    ],
    partners: [
      {
        pic: global.testim + "pic1.jpg",
        quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Peter Len",
        designation: "Web Designer"
      },
      {
        pic: global.testim + "pic2.jpg",
        quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Giselle",
        designation: "Developer"
      },
      {
        pic: global.testim + "pic3.jpg",
        quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Josephine",
        designation: "Web Designer"
      },
      {
        pic: global.testim + "pic4.jpg",
        quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Penelope",
        designation: "Developer"
      }
    ]
  },

  services: {
    title: "SOLUCIONES LOGÍSTICAS",
    subtitle: "Lo que nuestros clientes necesiten",
    description: "En Caribbean Smart Cargo mantenemos relaciones comerciales con una amplia cartera de proveedores de servicios de transporte marítimo, terrestre y aéreo, por lo que su carga puede viajar desde y hacia cualquier parte de la república Mexicana.",
    services: [
           
      {
        image: global.sgallery + "1.jpg",
        title: "Transporte terreste",
        serial: "01",
        description: "Tenemos alianzas comerciales con proveedores nacionales e internacionales de transporte terrestre."
      },
      {
        image: global.sgallery + "2.jpg",
        title: "Transporte Marítimo",
        serial: "02",
        description: "Además de nuestra flotilla, mantenemos relaciones comerciales con navieras del mundo para cubrir cualquier tramo necesario."
      },
      {
        image: global.sgallery + "3.jpg",
        title: "Transporte aéreo",
        serial: "03",
        description: "Tenemos alianzas comerciales con proveedores nacionales e internacionales de transporte aéreo."
      },
      {
        image: global.sgallery + "4.jpg",
        title: "Almacenaje",
        serial: "04",
        description: "Mantenemos su mercancía segura antes y después del abordaje. Nuestro servicio de bodega y almacenaje a través de nuestros aliados estratégicos, aseguran que su mercancía tenga una transición amigable en su ciclo de transporte."
      }
    ]
  },

  approach: {
    title: "Company Approch",
    subtitle: "Reliable Logistic & Transport Solutions",
    yearsOfExperience: "35",
    specifications: [
      {
        icon: global.icons + "c-pic1.png",
        count: 195,
        title: "Professional Staff"
      },
      {
        icon: global.icons + "c-pic2.png",
        count: 250,
        title: "On Time Delievery"
      },
      {
        icon: global.icons + "c-pic3.png",
        count: 350,
        title: "Technology and Media"
      }
    ]
  },

  blogs: {
    title: "BUQUE ORION",
    subtitle: "Llevamos tu carga comercial a Cuba",
    description: "Ro Ro Orión ofrece nueva ruta Progreso – Mariel con un servicio quincenal directo, continuo y regular.",
    blogs: [
      {
        image: global.blog1 + "bg1.jpg",
        day: "40 '",
        title: "STANDARD STEEL CONTAINER ",
        description: [
          "Medidas: 12.03 x 2.35 x 2.39 m",
           "Ancho y alto en puerta: 2.34 x 2.28 m ",
           "Capacidad cúbica: 67.7m3  ",
           "Capacidad máxima: 27,600 kgs",
          "Tara: 3,640 kg ",
          "Peso total max: 32,500 kg "
        ]
      },
      {
        image: global.blog2 + "l-1.jpg",
        day: "300",
        month: "pallets",
        title: "Carga suelta",
        description: [
          "Capacidad en bodega para 300 pallets sin estiba",
           "Medidas aprox 1.20 x 1.00 ",
          
        ]
      }
    ]
  },

  projects: {
    title: "Servicios Orión",
    subtitle: "Los servicios que ofrecemos son:",
    projects: [
      {
        image: global.projects + "1.jpg",
        title: "Importación",
        subtitle: "Recíbelo fácil y rápido ",
        caption: ""
      },
      {
        image: global.projects + "2.jpg",
        title: "Exportación",
        subtitle: "Hazles llegar tus productos puntualmente",
        caption: ""
      },
      {
        image: global.projects + "3.jpg",
        title: "Carga Consolidada",
        subtitle: "No hay un mínimo para enviar ",
        caption: ""
      },
      {
        image: global.projects + "4.jpg",
        title: "Contenedor Completo",
        subtitle: '40 pies para enviar tu proyecto',
        caption: ""
      },
      {
        image: global.projects + "5.jpg",
        title: "Transporte de Autos",
        subtitle: "RoRo especial para enviar autos",
        caption: ""
      }
    ]
  }
}
